/* General container styling */
.container {
  width: 100%;
  max-width: 1200px; /* On larger screens, limit width to 1200px */
  padding: 20px;
  box-sizing: border-box;
  background-color: #fff;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  display: flex;
  flex-direction: column;
}

/* Form elements */
input,
select,
button {
  width: 100%;
  padding: 10px;
  margin-bottom: 20px;
  font-size: 16px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

/* Button styles */
button {
  background-color: #28a745;
  color: #fff;
  cursor: pointer;
  font-size: 18px;
  font-weight: bold;
}

button:hover {
  background-color: #218838;
}

/* Checkbox alignment */
.checkbox-container {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

/* For the label and checkbox container */
.checkbox-container {
  display: flex;
  align-items: center; /* Aligns the checkbox and the label vertically */
  gap: 10px; /* Adds space between the checkbox and the label */
  margin-bottom: 20px; /* Adds spacing below the checkbox */
}

/* You can adjust this style if needed */
input[type="checkbox"] {
  margin: 0;
  padding: 0;
}

input[type="checkbox"] {
  width: auto; /* Prevent the checkbox from stretching to full width */
}

/* Log window styles */
.log-window {
  background-color: #f4f4f4;
  padding: 10px;
  border-radius: 4px;
  border: 1px solid #ccc;
  overflow-y: auto; /* Enable vertical scrolling */
  height: auto; /* Let the height adjust naturally */
  flex-grow: 1; /* Allow the log window to fill available space */
  min-height: 150px; /* Ensure it doesn’t collapse */
}

/* For mobile devices */
@media (max-width: 768px) {
  .container {
    width: 100%;
    min-width: 350px; /* Ensure the container doesn't get too small */
    max-width: 1200px;
    padding: 20px;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .log-window {
    flex-grow: 1;
    max-height: 40vh; /* Set a max height relative to the viewport height */
    overflow-y: auto; /* Enable scrolling for the log window only */
    background-color: #f4f4f4;
    padding: 10px;
    border-radius: 4px;
    border: 1px solid #ccc;
  }

  html,
  body {
    height: 100%;
    overflow-y: hidden; /* Avoid overall page scroll */
  }

  /* For the label and checkbox container */
  .checkbox-container {
    display: flex;
    align-items: center; /* Aligns the checkbox and the label vertically */
    gap: 10px; /* Adds space between the checkbox and the label */
    margin-bottom: 20px; /* Adds spacing below the checkbox */
  }

  /* You can adjust this style if needed */
  input[type="checkbox"] {
    margin: 0;
    padding: 0;
  }
}
